.ql-editor{    
    min-height: 100px;
    overflow: auto;    
}

.ql-toolbar {
    border-left: 1px solid !important;
    border-top: 1px solid !important;
    border-right: 1px solid !important;
    border-radius: 6px 6px 0px 0px;
    background-color: #eee;
    border-color: #ccc !important;
}

.ql-container{
    border-left: 1px solid !important;
    border-bottom: 1px solid !important;
    border-right: 1px solid !important;
    border-radius: 0px 0px 6px 6px;
    border-color: #ccc !important;
}

